exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-family-tree-js": () => import("./../../../src/pages/about/family-tree.js" /* webpackChunkName: "component---src-pages-about-family-tree-js" */),
  "component---src-pages-about-life-in-brief-js": () => import("./../../../src/pages/about/life-in-brief.js" /* webpackChunkName: "component---src-pages-about-life-in-brief-js" */),
  "component---src-pages-about-pandit-kshitish-kumar-vedalankar-js": () => import("./../../../src/pages/about/pandit-kshitish-kumar-vedalankar.js" /* webpackChunkName: "component---src-pages-about-pandit-kshitish-kumar-vedalankar-js" */),
  "component---src-pages-about-trust-js": () => import("./../../../src/pages/about/trust.js" /* webpackChunkName: "component---src-pages-about-trust-js" */),
  "component---src-pages-books-aadarsh-upay-aur-sujhav-js": () => import("./../../../src/pages/books/aadarsh-upay-aur-sujhav.js" /* webpackChunkName: "component---src-pages-books-aadarsh-upay-aur-sujhav-js" */),
  "component---src-pages-books-aapt-rastra-purush-js": () => import("./../../../src/pages/books/aapt-rastra-purush.js" /* webpackChunkName: "component---src-pages-books-aapt-rastra-purush-js" */),
  "component---src-pages-books-anubhumika-for-kashmir-khulasta-swarg-js": () => import("./../../../src/pages/books/Anubhumika-for-Kashmir-Khulasta-Swarg.js" /* webpackChunkName: "component---src-pages-books-anubhumika-for-kashmir-khulasta-swarg-js" */),
  "component---src-pages-books-aryasamaj-ki-vichardhara-js": () => import("./../../../src/pages/books/aryasamaj-ki-vichardhara.js" /* webpackChunkName: "component---src-pages-books-aryasamaj-ki-vichardhara-js" */),
  "component---src-pages-books-asliyat-kya-hai-js": () => import("./../../../src/pages/books/asliyat-kya-hai.js" /* webpackChunkName: "component---src-pages-books-asliyat-kya-hai-js" */),
  "component---src-pages-books-bangladesh-anubhoomika-js": () => import("./../../../src/pages/books/Bangladesh-Anubhoomika.js" /* webpackChunkName: "component---src-pages-books-bangladesh-anubhoomika-js" */),
  "component---src-pages-books-bangladesh-swatantrata-ke-baad-js": () => import("./../../../src/pages/books/bangladesh-swatantrata-ke-baad.js" /* webpackChunkName: "component---src-pages-books-bangladesh-swatantrata-ke-baad-js" */),
  "component---src-pages-books-bangladesh-swatantrata-ke-js": () => import("./../../../src/pages/books/Bangladesh-Swatantrata-Ke.js" /* webpackChunkName: "component---src-pages-books-bangladesh-swatantrata-ke-js" */),
  "component---src-pages-books-bharat-ko-hindu-rajya-ghoshit-karo-js": () => import("./../../../src/pages/books/bharat-ko-hindu-rajya-ghoshit-karo.js" /* webpackChunkName: "component---src-pages-books-bharat-ko-hindu-rajya-ghoshit-karo-js" */),
  "component---src-pages-books-bujhati-rakh-me-chamaktee-chingariyan-js": () => import("./../../../src/pages/books/Bujhati-Rakh-me-Chamaktee-Chingariyan.js" /* webpackChunkName: "component---src-pages-books-bujhati-rakh-me-chamaktee-chingariyan-js" */),
  "component---src-pages-books-chayanika-js": () => import("./../../../src/pages/books/chayanika.js" /* webpackChunkName: "component---src-pages-books-chayanika-js" */),
  "component---src-pages-books-desh-drishti-js": () => import("./../../../src/pages/books/desh-drishti.js" /* webpackChunkName: "component---src-pages-books-desh-drishti-js" */),
  "component---src-pages-books-devta-kursi-ke-js": () => import("./../../../src/pages/books/devta-kursi-ke.js" /* webpackChunkName: "component---src-pages-books-devta-kursi-ke-js" */),
  "component---src-pages-books-divya-dayanand-js": () => import("./../../../src/pages/books/divya-dayanand.js" /* webpackChunkName: "component---src-pages-books-divya-dayanand-js" */),
  "component---src-pages-books-gandhi-ji-ka-hasya-vinod-js": () => import("./../../../src/pages/books/gandhi-ji-ka-hasya-vinod.js" /* webpackChunkName: "component---src-pages-books-gandhi-ji-ka-hasya-vinod-js" */),
  "component---src-pages-books-gurukul-ki-aahuti-js": () => import("./../../../src/pages/books/gurukul-ki-aahuti.js" /* webpackChunkName: "component---src-pages-books-gurukul-ki-aahuti-js" */),
  "component---src-pages-books-hind-kee-chaadar-mein-daag-js": () => import("./../../../src/pages/books/Hind-Kee-Chaadar-Mein-Daag.js" /* webpackChunkName: "component---src-pages-books-hind-kee-chaadar-mein-daag-js" */),
  "component---src-pages-books-hindi-hee-kyon-js": () => import("./../../../src/pages/books/hindi-hee-kyon.js" /* webpackChunkName: "component---src-pages-books-hindi-hee-kyon-js" */),
  "component---src-pages-books-index-js": () => import("./../../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-books-introduction-to-contents-js": () => import("./../../../src/pages/books/introduction-to-contents.js" /* webpackChunkName: "component---src-pages-books-introduction-to-contents-js" */),
  "component---src-pages-books-ishwar-vaijyanikon-ki-drishti-mein-js": () => import("./../../../src/pages/books/ishwar-vaijyanikon-ki-drishti-mein.js" /* webpackChunkName: "component---src-pages-books-ishwar-vaijyanikon-ki-drishti-mein-js" */),
  "component---src-pages-books-jaati-dharm-bhasha-js": () => import("./../../../src/pages/books/jaati-dharm-bhasha.js" /* webpackChunkName: "component---src-pages-books-jaati-dharm-bhasha-js" */),
  "component---src-pages-books-jati-bhed-ka-abhishaap-js": () => import("./../../../src/pages/books/jati-bhed-ka-abhishaap.js" /* webpackChunkName: "component---src-pages-books-jati-bhed-ka-abhishaap-js" */),
  "component---src-pages-books-kashmir-jhulasta-swarg-js": () => import("./../../../src/pages/books/Kashmir-Jhulasta-Swarg.js" /* webpackChunkName: "component---src-pages-books-kashmir-jhulasta-swarg-js" */),
  "component---src-pages-books-kashmir-js": () => import("./../../../src/pages/books/kashmir.js" /* webpackChunkName: "component---src-pages-books-kashmir-js" */),
  "component---src-pages-books-list-of-books-and-compilations-js": () => import("./../../../src/pages/books/list-of-books-and-compilations.js" /* webpackChunkName: "component---src-pages-books-list-of-books-and-compilations-js" */),
  "component---src-pages-books-london-smarika-js": () => import("./../../../src/pages/books/London-Smarika.js" /* webpackChunkName: "component---src-pages-books-london-smarika-js" */),
  "component---src-pages-books-mauritius-smarika-1974-js": () => import("./../../../src/pages/books/Mauritius-Smarika-1974.js" /* webpackChunkName: "component---src-pages-books-mauritius-smarika-1974-js" */),
  "component---src-pages-books-nizam-ki-jail-me-js": () => import("./../../../src/pages/books/nizam-ki-jail-me.js" /* webpackChunkName: "component---src-pages-books-nizam-ki-jail-me-js" */),
  "component---src-pages-books-o-mere-rajhans-js": () => import("./../../../src/pages/books/o-mere-rajhans.js" /* webpackChunkName: "component---src-pages-books-o-mere-rajhans-js" */),
  "component---src-pages-books-padosi-desh-js": () => import("./../../../src/pages/books/padosi-desh.js" /* webpackChunkName: "component---src-pages-books-padosi-desh-js" */),
  "component---src-pages-books-pandim-ke-durgam-path-par-js": () => import("./../../../src/pages/books/pandim-ke-durgam-path-par.js" /* webpackChunkName: "component---src-pages-books-pandim-ke-durgam-path-par-js" */),
  "component---src-pages-books-phir-is-andaaz-se-bahar-aayi-js": () => import("./../../../src/pages/books/phir-is-andaaz-se-bahar-aayi.js" /* webpackChunkName: "component---src-pages-books-phir-is-andaaz-se-bahar-aayi-js" */),
  "component---src-pages-books-praantiyata-aur-aatankwad-js": () => import("./../../../src/pages/books/praantiyata-aur-aatankwad.js" /* webpackChunkName: "component---src-pages-books-praantiyata-aur-aatankwad-js" */),
  "component---src-pages-books-pt-kshitish-vedalankar-ki-kritiyan-js": () => import("./../../../src/pages/books/pt-kshitish-vedalankar-ki-kritiyan.js" /* webpackChunkName: "component---src-pages-books-pt-kshitish-vedalankar-ki-kritiyan-js" */),
  "component---src-pages-books-rajneeti-nahi-rashtraneeti-js": () => import("./../../../src/pages/books/rajneeti-nahi-rashtraneeti.js" /* webpackChunkName: "component---src-pages-books-rajneeti-nahi-rashtraneeti-js" */),
  "component---src-pages-books-rajneetik-uthapatak-js": () => import("./../../../src/pages/books/rajneetik-uthapatak.js" /* webpackChunkName: "component---src-pages-books-rajneetik-uthapatak-js" */),
  "component---src-pages-books-rashtra-prahari-arya-samaj-js": () => import("./../../../src/pages/books/rashtra-prahari-arya-samaj.js" /* webpackChunkName: "component---src-pages-books-rashtra-prahari-arya-samaj-js" */),
  "component---src-pages-books-rashtriya-ekta-ki-buniyaden-js": () => import("./../../../src/pages/books/rashtriya-ekta-ki-buniyaden.js" /* webpackChunkName: "component---src-pages-books-rashtriya-ekta-ki-buniyaden-js" */),
  "component---src-pages-books-rashtriya-patrakarita-ke-purodha-js": () => import("./../../../src/pages/books/rashtriya-patrakarita-ke-purodha.js" /* webpackChunkName: "component---src-pages-books-rashtriya-patrakarita-ke-purodha-js" */),
  "component---src-pages-books-rashtriya-sarkar-js": () => import("./../../../src/pages/books/rashtriya-sarkar.js" /* webpackChunkName: "component---src-pages-books-rashtriya-sarkar-js" */),
  "component---src-pages-books-satavlekar-abhinandan-granth-js": () => import("./../../../src/pages/books/Satavlekar-Abhinandan-Granth.js" /* webpackChunkName: "component---src-pages-books-satavlekar-abhinandan-granth-js" */),
  "component---src-pages-books-storm-in-punjab-js": () => import("./../../../src/pages/books/storm-in-punjab.js" /* webpackChunkName: "component---src-pages-books-storm-in-punjab-js" */),
  "component---src-pages-books-svetlana-js": () => import("./../../../src/pages/books/svetlana.js" /* webpackChunkName: "component---src-pages-books-svetlana-js" */),
  "component---src-pages-books-toofan-ke-daur-se-js": () => import("./../../../src/pages/books/Toofan-Ke-Daur-Se.js" /* webpackChunkName: "component---src-pages-books-toofan-ke-daur-se-js" */),
  "component---src-pages-books-toofan-ke-daur-se-punjab-js": () => import("./../../../src/pages/books/toofan-ke-daur-se-punjab.js" /* webpackChunkName: "component---src-pages-books-toofan-ke-daur-se-punjab-js" */),
  "component---src-pages-books-uttarakhand-ke-path-par-js": () => import("./../../../src/pages/books/uttarakhand-ke-path-par.js" /* webpackChunkName: "component---src-pages-books-uttarakhand-ke-path-par-js" */),
  "component---src-pages-books-vishwa-paridrisya-videsh-neeti-js": () => import("./../../../src/pages/books/vishwa-paridrisya-videsh-neeti.js" /* webpackChunkName: "component---src-pages-books-vishwa-paridrisya-videsh-neeti-js" */),
  "component---src-pages-books-vitta-neeti-js": () => import("./../../../src/pages/books/vitta-neeti.js" /* webpackChunkName: "component---src-pages-books-vitta-neeti-js" */),
  "component---src-pages-classification-of-books-js": () => import("./../../../src/pages/classificationOfBooks.js" /* webpackChunkName: "component---src-pages-classification-of-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kshitish-granthavali-volumes-js": () => import("./../../../src/pages/KshitishGranthavaliVolumes.js" /* webpackChunkName: "component---src-pages-kshitish-granthavali-volumes-js" */)
}

